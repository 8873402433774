<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? ($t('cip.plat.wfDesign.defaultValues.title.editHeadTitle')) : ($t('cip.plat.wfDesign.defaultValues.title.addHeadTitle'))"
        @head-save="headSave('save')"
        @head-save-back="headSave('back')"
        @head-cancel="headCancel">
      </head-layout>

      <form-layout
        :column="option.column"
        :dataForm.sync="dataObj"
        ref="formLayout">
      </form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {update, add} from "@/api/plugin/workflow/form-default-values";

export default {
  components: {FormLayout, HeadLayout},

  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      dataObj: {},
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        index: true,
        selection: true,
        dialogType: 'drawer',
        align: 'center',
        searchMenuSpan: 6,
        column: [
          {
            label: this.$t(`cip.plat.wfDesign.defaultValues.field.name`),
            prop: "name",
            maxlength:64,
            showWordLimit:true,
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.defaultValues.field.name`),
              trigger: "blur"
            }, {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }],
          },
          {
            label:  this.$t(`cip.plat.wfDesign.defaultValues.field.content`),
            prop: "content",
            maxlength: 200,
            placeholder: this.$t(`cip.plat.wfDesign.defaultValues.msg.content`),
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.defaultValues.field.content`),
              trigger: "blur"
            }],
          },
          {
            label:  this.$t(`cip.plat.wfDesign.defaultValues.field.fieldType`),
            prop: "fieldType",
            type: 'select',
            filterable: true,
            allowCreate: true,
            dicData: [{
              label: 'input',
              value: 'input'
            }, {
              label: 'textarea',
              value: 'textarea'
            }, {
              label: 'select',
              value: 'select'
            }, {
              label: 'tree',
              value: 'tree'
            }],
            rules: [{
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`)+this.$t(`cip.plat.wfDesign.defaultValues.field.fieldType`),
              trigger: "blur"
            }],
          },
        ]
      },
    }
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (this.$route.query.type == 'edit') {
      this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
    }
  },
  methods: {
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading();
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(this.dataObj).then((res) => {
            this.$loading().close()
            if (type == 'back') {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
            this.dataObj = res.data.data
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.isShow = false
            //
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      }).finally(() => {
        this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data));
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
